<template>
  <lottie-animation
    ref="anim"
    :animationData="require('@/assets/anim/tarifada.json')"
    :loop="true"
    :autoPlay="true"
    :speed="1"
    class="svg_wrapper hover_lottie"
  />
  <!-- <button class="btn btn-yup-purple" @click="playLottie">Play</button> -->
</template>
<script>
import LottieAnimation from 'lottie-web-vue';

export default {
  components: {
    LottieAnimation,
  },
  mounted() {},
  methods: {
    hoverLottieEnter() {
      this.$refs.anim.play();
      this.$refs.anim.setDirection(1);
    },
    hoverLottieLeave() {
      this.$refs.anim.play();
      this.$refs.anim.setDirection(-1);
    },
    playLottie() {
      this.$refs.anim.play();
    },
  },
};
</script>
<style scoped lang="scss">
.hover_lottie {
  position: relative;
  z-index: 5;
}
.svg_wrapper {
  position: relative;
  top: -10px;
  width: 80px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
</style>
